import React from 'react'
import PropTypes from 'prop-types'
import ContactFormSection from '../../containers/contact/contact-form'

import {graphql} from 'gatsby'
import SEO from '../../components/seo';
import Layout from '../../containers/layout/layout'
import Heading from '../../components/shared/heading'
import Text from '../../components/shared/text'
import {
    BlogDetailsWrap,
    BlogDetailsHeader,
    BlogDetailsContent,
} from './services-template.stc'
 
function ServicesTemplate({data, ...restProps}) {
    const {
        titleStyle, 
        metaTextStyle
    } = restProps;
    const post = data.markdownRemark.frontmatter;
    const imageData = post.image.childImageSharp.fluid;

    return (
        <Layout>
            <SEO title={post.titleSEO} description={post.descSEO}/>
            <BlogDetailsWrap>
                <BlogDetailsHeader fluid={imageData}>
                    <div className="row align-items-center">
                        <div className="col-3 offset-1">
                            <div className="rn-blog-details-meta-inner">
                                <Text {...metaTextStyle}>Услуги</Text>
                                <Heading {...titleStyle}>{post.title}</Heading>
                                <Text {...metaTextStyle}><span dangerouslySetInnerHTML={{__html: post.desc}}/></Text>
                            </div>
                        </div>
                    </div>
                </BlogDetailsHeader>
                <BlogDetailsContent>
                    <div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
                </BlogDetailsContent>
            </BlogDetailsWrap>
            <ContactFormSection/>
        </Layout>
    )
}

export const query = graphql `
    query($slug: String!){
        markdownRemark(fields: {slug: {eq: $slug}}){
            frontmatter {
                id
                title
                desc
                titleSEO
                descSEO
                date(formatString: "MMM Do, YYYY")
                author
                image {
                    childImageSharp {
                      fluid(maxWidth: 1920, maxHeight: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                      }
                    }
                }
            }  
            html 
        }
    }
`;

ServicesTemplate.propTypes = {
    metaHeadingStyle: PropTypes.object,
    metaTextStyle: PropTypes.object,
    titleStyle: PropTypes.object
}

ServicesTemplate.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: '#fff',
        responsive: {
            small:{
                mb: '22px'
            }
        }
    },
    metaBoxStyle: {
        mb: '30px',
        responsive: {
            small: {
                mb: 0,
                pt: '11px',
                pb: '11px'
            }
        }
    },
    metaHeadingStyle: {
        as: 'h6',
        color: '#fff',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px'
    },
    metaTextStyle: {
        m: 0,
        fontSize: '16px',
        color: '#fff',
        letterspacing: '1px'
    }
}

export default ServicesTemplate

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import Heading from "../../../components/shared/heading";
import Text from "../../../components/shared/text";
import Button from "../../../components/shared/button";
import Form, { Input, Textarea } from "../../../components/shared/form";
import Image from "../../../components/image";
import { navigate, useLocation } from "@reach/router";
import {
  ContactSectionWrap,
  BannerArea,
  ContactFormWrap,
  ContactForm,
} from "./contact-form.stc";

const ContactFormSection = ({
  headingStyle,
  textStyle,
  inputStyle,
  textareaStyle,
}) => {
  const bannerQueryData = useStaticQuery(graphql`
    query ContactBannerQuery {
      pagedataJson(id: { eq: "contact_page_data" }) {
        title
        subtitle
        banner_img {
          childImageSharp {
            fluid(maxWidth: 700, maxHeight: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();

  const { title, subtitle } = bannerQueryData.pagedataJson;
  const imageData =
    bannerQueryData.pagedataJson.banner_img.childImageSharp.fluid;

  const handleChange = (event) => {
    switch (event.target.name) {
      case "Name":
        setName(event.target.value);
        break;
      case "Phone":
        setPhone(event.target.value);
        break;
      case "Message":
        setMessage(event.target.value);
        break;
      default:
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (window.ym) {
      window.ym(64608949, "reachGoal", location.pathname === "/" ? "home" : location.pathname.slice(1));
    }

    let requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    let url = "https://functions.yandexcloud.net/d4e7pot8921d86r2he62";
    fetch(
      `${url}?name=${name}&message=${message}&phone=${phone}&email=${location.href}`,
      requestOptions
    )
      .then((response) => {
        response.text();
        navigate("/modal/", { state: { modal: true, noScroll: true } });
        setName("");
        setPhone("");
        setMessage("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <ContactSectionWrap>
      <div className="row align-items-center">
        <div className="col-2">
          <BannerArea id="contact-form-banner">
            <Image fluid={imageData} alt="Contact" />
          </BannerArea>
        </div>
        <div className="col-2 offset-1">
          <ContactFormWrap>
            <div className="rn-project-meta-inner">
              {title && <Text {...textStyle}>{title}</Text>}
              {subtitle && <Heading {...headingStyle}>{subtitle}</Heading>}
            </div>
            <ContactForm>
              <Form>
                <Input
                  type="text"
                  name="Name"
                  id="name"
                  placeholder="Ваше имя"
                  {...inputStyle}
                  value={name}
                  onChange={handleChange}
                />
                <Input
                  type="phone"
                  name="Phone"
                  id="phone"
                  placeholder="Телефон"
                  value={phone}
                  onChange={handleChange}
                />
                <Textarea
                  name="Message"
                  placeholder="Комментарий"
                  {...textareaStyle}
                  value={message}
                  onChange={handleChange}
                />
                <Button type="submit" layout={2} onClick={sendEmail}>
                  ЗАКАЗАТЬ ЗВОНОК
                </Button>
                <p>
                  Нажимая кнопку, вы даете согласие c{" "}
                  <Link to="/privacy">
                    <b>политикой конфиденциальности</b>
                  </Link>{" "}
                  на обработку ваших личных данных в соответствии с ФЗ РФ №
                  152-ФЗ «О персональных данных»
                </p>
              </Form>
            </ContactForm>
          </ContactFormWrap>
        </div>
      </div>
    </ContactSectionWrap>
  );
};

ContactFormSection.propTypes = {
  headingStyle: PropTypes.object,
  textStyle: PropTypes.object,
  textareaStyle: PropTypes.object,
};

ContactFormSection.defaultProps = {
  headingStyle: {
    fontSize: "40px",
    lineHeight: "55px",
    color: "primary",
    fontweight: 600,
    responsive: {
      medium: {
        fontSize: "24px",
        lineHeight: "initial",
      },
    },
  },
  textStyle: {
    as: "span",
    color: "#000000",
    fontweight: 500,
    fontSize: "16px",
    texttransform: "uppercase",
    letterspacing: "1px",
    display: "inline-block",
    mb: "17px",
    lineHeight: 1,
    responsive: {
      medium: {
        fontSize: "12px",
        mb: "10px",
      },
    },
  },
  inputStyle: {
    responsive: {
      xsmall: {
        mb: "20px",
      },
    },
  },
  textareaStyle: {
    mt: "40px",
    mb: "40px",
    responsive: {
      xsmall: {
        mt: "25px",
        mb: "25px",
      },
    },
  },
};
Button.defaultProps = {
  fontSize: "16px",
};
export default ContactFormSection;

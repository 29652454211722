import styled, {css} from 'styled-components';
import {device} from '../../../../theme'

export const TextareaWrap = styled.textarea `
    color: ${props => props.theme.colors.headingColor};
    font-size: 18px;
    border: 0 none;
    font-weight: 300;
    border-bottom: 1px solid #ebebec;
    min-height: 100px;
    margin-top: ${props => props.mt};
    margin-bottom: ${props => props.mb};
    width: 100%;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #7c7d7e;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #7c7d7e;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #7c7d7e;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #7c7d7e;
    }
    ${props => props.halfwidth && css `
        width: 50%;
        float: left;
    `}
    ${props => props.responsive && css `
        ${props => props.responsive.xsmall && css `
            @media ${device.xsmall}{
                margin-top: ${props => props.responsive.xsmall.mt};
                margin-bottom: ${props => props.responsive.xsmall.mb};
            }
        `}
    `}
`;